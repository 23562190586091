import * as React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import get from 'lodash/get'

class Contact extends React.Component {
  render() {
    const data = get(this, 'props.data.allContentfulContact.nodes')

    if (!data) {
      return <></>
    }

    const { location, email, phone } = data[0]
    console.log(location, email, phone)
    return (
      <Layout>
        <Seo title="Contact" />
        <Hero title="Contact" />
        <div>{location}</div>
        <div>{email}</div>
        <div>{phone}</div>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    allContentfulContact {
      nodes {
        email
        phone
        location
      }
    }
  }
`
